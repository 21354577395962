import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/build_4388d3d8/src/components/posts/PostLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`One of my favorite ways of improving my knowledge of "vanilla" JavaScript and
the browser's DOM API is to create complex UI components without utilizing any
libraries or frameworks. It's a great way to familiarize myself with the methods
available for DOM manipulation, event handling, and information retrieval. Along
the way, I always pick up a few tips and tricks to add to my front end toolbelt.
The technique fits in nicely with my philosophy that one of the best ways to
learn in programming is to "re-invent the wheel".`}</p>
    <p>{`In this post, I'm going to walk through implementing an image zoomer component.
It's basically what you use on a site like Amazon to get a closer look at an
image by hovering your mouse over a thumbnail. I'll be building a slightly
simplified implementation `}<a parentName="p" {...{
        "href": "https://whastings.github.io/ui-components/image-zoomer/"
      }}>{`based on this implementation from my UI Components
project`}</a>{` (`}<a parentName="p" {...{
        "href": "https://github.com/whastings/ui-components/tree/master/lib/image-zoomer"
      }}>{`code here`}</a>{`). Along the way, I'll touch on
topics like listening to mouse events, getting an element's position within the
viewport, and calculating the position of the zoomed image.`}</p>
    <h2>{`Defining the Component`}</h2>
    <p>{`To keep things simple, we'll define our component as a JavaScript class whose
constructor takes an image element. In the real world, a class like this can be
hooked into a more declarative system for instantiating components. For example,
in my original implementation, I define an `}<inlineCode parentName="p">{`<image-zoomer>`}</inlineCode>{` custom element that
I can use for creating instances.`}</p>
    <p>{`So to start off, here's our class:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class ImageZoomer {
  constructor(image) {
    this.image = image;
  }
}
`}</code></pre>
    <h2>{`Setting up the DOM`}</h2>
    <p>{`Before we can start coding the logic to handle the behavior of the image zoomer,
we need to modify our initialization logic to transform the DOM into the state
we need it to be in. We'll need the following:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Zoom Box:`}</strong>{` A `}<inlineCode parentName="li">{`div`}</inlineCode>{` to represent the box that surrounds the cursor and
outlines the zoomed portion of the image while the user hovers over the
original image. We'll reference this element as `}<inlineCode parentName="li">{`this.zoomBox`}</inlineCode>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Zoom Container:`}</strong>{` A `}<inlineCode parentName="li">{`div`}</inlineCode>{` to wrap the original image and the zoom box. Its
job is to support positioning the zoom box over the image, so it should be the
same size as the image. Well reference it as `}<inlineCode parentName="li">{`this.zoomContainer`}</inlineCode>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Zoomed Image:`}</strong>{` A copy of the original image that we'll allow to grow to its
full size so it can be "zoomed". For this effect to work well, the layout in
which the image zoomer component is used should display the original image at
a much smaller size than it's true size. We'll reference the zoomed image as
`}<inlineCode parentName="li">{`this.zoomedImage`}</inlineCode>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Zoom Window:`}</strong>{` A `}<inlineCode parentName="li">{`div`}</inlineCode>{` to contain the zoomed image. It should be smaller
than the full size of the image to ensure that only a portion of the zoomed
image is visible. We'll move the zoomed image around inside the zoom window as
the user moves the mouse cursor over the original image. We'll reference the
zoom window as `}<inlineCode parentName="li">{`this.zoomWindow`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`Now that we know what DOM structure we'll need, let's add the logic to set it
up:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class ImageZoomer {
  constructor(image) {
    let parentEl = image.parentNode;
    this.image = image;

    this.zoomedImage = image.cloneNode();
    this.zoomWindow = createZoomWindow(this.zoomedImage);

    this.zoomBox = createZoomBox();
    this.zoomContainer = createZoomContainer(image, this.zoomBox);

    parentEl.appendChild(this.zoomContainer);
    parentEl.appendChild(this.zoomWindow);
  }
}

function createZoomBox() {
  let zoomBox = document.createElement('div');
  zoomBox.classList.add('zoom-box');
  return zoomBox;
}

function createZoomContainer(image, zoomBox) {
  let zoomContainer = document.createElement('div');
  zoomContainer.classList.add('zoom-container');
  zoomContainer.appendChild(image);
  zoomContainer.appendChild(zoomBox);
  return zoomContainer;
}

function createZoomWindow(zoomedImage) {
  let zoomWindow = document.createElement('div');
  zoomWindow.classList.add('zoom-window');

  zoomedImage.setAttribute('aria-hidden', 'true');
  zoomWindow.appendChild(zoomedImage);
  return zoomWindow;
}

let image = document.querySelector('.image-zoomer-demo img');
let zoomer = new ImageZoomer(image);
`}</code></pre>
    <p>{`Say our initial HTML is the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="image-zoomer-demo">
  <img src="..." alt="...">
</div>
`}</code></pre>
    <p>{`Executing the JS code will change the DOM to the following structure:`}</p>
    <ul>
      <li parentName="ul">{`div.image-zoomer-demo`}<ul parentName="li">
          <li parentName="ul">{`div.zoom-container`}<ul parentName="li">
              <li parentName="ul">{`img (the original image)`}</li>
              <li parentName="ul">{`div.zoom-box`}</li>
            </ul></li>
          <li parentName="ul">{`div.zoom-window`}<ul parentName="li">
              <li parentName="ul">{`img (the cloned `}<inlineCode parentName="li">{`zoomedImage`}</inlineCode>{` for zooming)`}</li>
            </ul></li>
        </ul></li>
    </ul>
    <p>{`Here are a few important points to note about the code above:`}</p>
    <ul>
      <li parentName="ul">{`We take advantage of the browser's `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/Document/createElement"
        }}><inlineCode parentName="a">{`document.createElement`}</inlineCode></a>{`
API to create new elements like the zoom container, zoom box, and zoom window.`}</li>
      <li parentName="ul">{`We use the `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/Node/cloneNode"
        }}><inlineCode parentName="a">{`cloneNode`}</inlineCode></a>{` API to make a copy of the original image
to use as our zoomed image.`}</li>
      <li parentName="ul">{`We use the `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/Element/classList"
        }}><inlineCode parentName="a">{`classList`}</inlineCode></a>{` API for adding CSS classes to our new
elements.`}</li>
      <li parentName="ul">{`We use the `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/Node/appendChild"
        }}><inlineCode parentName="a">{`appendChild`}</inlineCode></a>{` API for adding elements to other
elements to achieve our desired DOM structure.`}</li>
    </ul>
    <p>{`These APIs are some of the handiest built-in browser APIs for manipulating the
DOM. They may not be as elegant as jQuery's API, but they certainly get the job
done and done cheaply.`}</p>
    <p>{`Note also that the `}<inlineCode parentName="p">{`createZoomBox()`}</inlineCode>{` function is not yet complete, as we'll be
adding logic to properly size the zoom box in an upcoming section.`}</p>
    <h2>{`Setting up the Styles`}</h2>
    <p>{`Now that we have the JS to initialize our component state and DOM structure,
let's shift our focus for a moment to styling. We want to apply styling that
will:`}</p>
    <ul>
      <li parentName="ul">{`Size the original image smaller than the zoomed image, with the original image
and the zoomed image laid out next to each other.`}</li>
      <li parentName="ul">{`Size the zoom window to be (roughly) a square.`}</li>
      <li parentName="ul">{`Ensure the zoomed image does not shrink inside the zoom window but also that
the part of it that overflows the zoom window is not visible.`}</li>
      <li parentName="ul">{`Set the zoom box and the zoomed image up to be positioned dynamically.`}</li>
      <li parentName="ul">{`Keep the zoom box and zoomed image invisible until we want to show them.`}</li>
    </ul>
    <p>{`Here's our styling. Note that I've written it with Sass to keep it
concise.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.image-zoomer-demo {
  display: flex;
  margin: 0 auto;
  max-width: 600px;
}

.zoom-container {
  flex: 1;
  padding-right: 25px;
  position: relative;

  img {
    max-width: 100%;
  }
}

.zoom-box {
  backface-visibility: hidden; // Hack to activate GPU acceleration.
  border: 2px lightblue solid;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;

  &.active {
    opacity: 1;
  }
}

.zoom-window {
  border: 1px #31708f solid;
  flex: 2;
  padding-bottom: calc(66.66% - 25px);
  overflow: hidden;
  position: relative;

  img {
    backface-visibility: hidden; // Hack to activate GPU acceleration.
    position: absolute;
    opacity: 0;
  }

  &.active img {
    opacity: 1;
  }
}
`}</code></pre>
    <p>{`Here are some important points to note about our styling code:`}</p>
    <ul>
      <li parentName="ul">{`We use Flexbox to lay out the zoom container and zoom window horizontally,
sizing the zoom container roughly one-third of the container's width and the
zoom window roughly two-thirds of the container's width. We add `}<inlineCode parentName="li">{`max-width:
100%`}</inlineCode>{` to the original image in the zoom container so it doesn't grow larger
than the container.`}</li>
      <li parentName="ul">{`We use the `}<a parentName="li" {...{
          "href": "http://andyshora.com/css-image-container-padding-hack.html"
        }}>{`padding hack`}</a>{` to make the zoom window's height
roughly equal to its width.`}</li>
      <li parentName="ul">{`We prevent the part of the zoomed image that exceeds the bounds of the zoom
window from showing by setting `}<inlineCode parentName="li">{`overflow: hidden`}</inlineCode>{` on the zoom window.`}</li>
      <li parentName="ul">{`We absolutely position the zoom box and the zoomed image, as well as
relatively position their containers, so they will render at the top left
corners of their containers before we position them dynamically.`}</li>
      <li parentName="ul">{`We set `}<inlineCode parentName="li">{`opacity: 0`}</inlineCode>{` on the zoom box and zoomed image so they won't be visible
initially. We also add styling for `}<inlineCode parentName="li">{`active`}</inlineCode>{` classes that, when added to the
zoom box or zoomed image, will reveal them by setting opacity to 1.`}</li>
    </ul>
    <p>{`A quick note on performance: We could have used `}<inlineCode parentName="p">{`display: none`}</inlineCode>{` instead of
`}<inlineCode parentName="p">{`opacity: 0`}</inlineCode>{` to hide the zoom box and the zoomed image. But opacity is a better
choice for performance because it is one of the things the browser can use the
GPU to animate, a process known as hardware acceleration. Using the GPU leads to
smoother animation as the browser does not have to re-paint the areas of the
screen that are animating. Instead, the browser paints the elements to be
animated to "layers" that are uploaded to the GPU, which it can then animate.
Note that the browser doesn't always create new layers for the GPU when you want
it to, so you can force it to do so by setting certain properties like
`}<inlineCode parentName="p">{`backface-visibility`}</inlineCode>{` (in the future, `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/will-change"
      }}><inlineCode parentName="a">{`will-change`}</inlineCode></a>{` will be the
official mechanism). For more on this, check out `}<a parentName="p" {...{
        "href": "https://www.html5rocks.com/en/tutorials/speed/high-performance-animations/"
      }}>{`"High Performance
Animations"`}</a>{` on HTML5 Rocks.`}</p>
    <h2>{`Sizing the Zoom Box`}</h2>
    <p>{`The next step in building the image zoomer is to size the zoom box. We want to
size it so it covers the same area on the original image that is visible in the
zoom window. This sizing must be done dynamically in JavaScript so we can
support variable widths for our layout (e.g. if it's placed somewhere smaller
than its current max width of 600 pixels). Here's the relevant update to the
code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class ImageZoomer {
  constructor(image)  {
    // Same code as before...
    sizeZoomBox(this.zoomBox, image, this.zoomWindow, this.zoomedImage);
  }
}

function sizeZoomBox(zoomBox, image, zoomWindow, zoomedImage) {
  let widthPercentage = zoomWindow.clientWidth / zoomedImage.clientWidth;
  let heightPercentage = zoomWindow.clientHeight / zoomedImage.clientHeight;

  zoomBox.style.width = Math.round(image.clientWidth * widthPercentage) + 'px';
  zoomBox.style.height = Math.round(image.clientHeight * heightPercentage) + 'px';
}
`}</code></pre>
    <p>{`Here we add a new `}<inlineCode parentName="p">{`sizeZoomBox()`}</inlineCode>{` function which we call at the end of the
`}<inlineCode parentName="p">{`constructor`}</inlineCode>{`. The function will be accessing the computed dimensions of the
original image, the zoom window, and the zoomed image in order to calculate the
zoom box's size. Therefore, it must be called after these elements have been
added to the DOM. The function works as follows:`}</p>
    <ul>
      <li parentName="ul">{`Calculate the percentage of the zoomed image's width that fits in the zoom
window: zoom window's width / zoomed image's width.`}</li>
      <li parentName="ul">{`Calculate the percentage of the zoomed image's height that fits in the zoom
window: zoom window's height / zoomed image's height.`}</li>
      <li parentName="ul">{`Set the zoom box's width to the original image's width times the width
percentage calculated in the first step (rounded).`}</li>
      <li parentName="ul">{`Set the zoom box's height to the original image's height times the height
percentage calculated in the second step (rounded).`}</li>
    </ul>
    <p>{`This gives us dimensions for the zoom box that are proportional to the zoomed
image inside the zoom window. As far as DOM APIs go, we use the `}<inlineCode parentName="p">{`clientWidth`}</inlineCode>{`
and `}<inlineCode parentName="p">{`clientHeight`}</inlineCode>{` properties to get the width and height of our elements in
pixels, and we use the `}<inlineCode parentName="p">{`style`}</inlineCode>{` property to set the zoom box's width and height.
One additional consideration would be to re-run this operation if the user
changes the size of the browser window. I'll leave that as an exercise for the
reader.`}</p>
    <p>{`A quick performance-related note: The code in its current state will cause a
forced synchronous layout. What this means is that when we ask for the
`}<inlineCode parentName="p">{`clientWidth`}</inlineCode>{` and `}<inlineCode parentName="p">{`clientHeight`}</inlineCode>{` of our elements, the browser will have to pause
our JS and do layout calculations before it can give us those values. For our
purposes this probably isn't a big deal, but too much of this kind of thing can
cause `}<a parentName="p" {...{
        "href": "http://wilsonpage.co.uk/preventing-layout-thrashing/"
      }}>{`layout thrashing`}</a>{`. In my original implementation, I
avoid the synchronous layout by lazily creating the zoom box element when the
user first mouses over the image.`}</p>
    <h2>{`Moving the Zoom Box`}</h2>
    <p>{`Now that we have everything laid out, let's get something interesting happening:
moving the zoom box to follow the mouse cursor while it's over the original
image.`}</p>
    <h3>{`Setting Up Event Listeners`}</h3>
    <p>{`First, we need to set up our event listeners. After initialization, we'll start
listening for the mouse to enter the original image or the zoom box (since it's
sitting on top of the image). Once the mouse enters, we'll show the zoom box,
stop listening for mouse enter, and start listening for mouse move events so we
can move the zoom box in response.`}</p>
    <p>{`The following code additions will set up our listener logic:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class ImageZoomer {
  constructor(image) {
    // Same code as before...
    this.activate = this.activate.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.listenForMouseEnter();
  }

  activate() {
    this.zoomBox.classList.add('active');
    this.zoomWindow.classList.add('active');
    this.listenForMouseMove();
  }

  handleMouseMove() {
    // TODO
  }

  listenForMouseEnter() {
    let { image, zoomBox } = this;
    document.body.removeEventListener('mousemove', this.handleMouseMove);
    image.addEventListener('mouseenter', this.activate);
    zoomBox.addEventListener('mouseenter', this.activate);
  }

  listenForMouseMove() {
    let { image, zoomBox } = this;
    image.removeEventListener('mouseenter', this.activate);
    zoomBox.removeEventListener('mouseenter', this.activate);
    document.body.addEventListener('mousemove', this.handleMouseMove);
  }
}
`}</code></pre>
    <p>{`This gets us as far as showing the zoom box when the user mouses over the
original image and switching from listening to mouse enter to mouse move.
Important points to note:`}</p>
    <ul>
      <li parentName="ul">{`In the constructor we bind methods that will be passed to the browser's
`}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener"
        }}><inlineCode parentName="a">{`addEventListener`}</inlineCode></a>{` and
`}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener"
        }}><inlineCode parentName="a">{`removeEventListener`}</inlineCode></a>{` APIs so they don't lose their
context (`}<inlineCode parentName="li">{`this`}</inlineCode>{` value) when invoked.`}</li>
      <li parentName="ul">{`We always remove our `}<inlineCode parentName="li">{`mousemove`}</inlineCode>{` listener when adding our `}<inlineCode parentName="li">{`mouseenter`}</inlineCode>{`
listeners, as that will be necessary once we implement the code to handle the
mouse leaving the image. For the first call, the browser doesn't care if we
try to remove a listener that hasn't been added.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`activate`}</inlineCode>{` method adds the `}<inlineCode parentName="li">{`active`}</inlineCode>{` class to the zoom box and zoom window
so the zoom box and the image in the zoom window will become visible.`}</li>
    </ul>
    <h3>{`Handling Mouse Move Events`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`handleMouseMove`}</inlineCode>{` method will be responsible for responding to mouse move
events and either moving the zoom box or hiding it if the cursor has left the
image. Moving the zoom box will require calculating a position that places it
around the cursor.`}</p>
    <p>{`Let's implement `}<inlineCode parentName="p">{`handleMouseMove`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class ImageZoomer {
  constructor(image) {
    // Same code as before...
    this.imageBounds = toDocumentBounds(image.getBoundingClientRect());
  }
  // ...

  handleMouseMove(event) {
    if (this.isMoveScheduled) {
      return;
    }

    window.requestAnimationFrame(() => {
      if (isWithinImage(this.imageBounds, event)) {
        this.updateUI(event.pageX, event.pageY);
      } else {
        this.deactivate();
      }
      this.isMoveScheduled = false;
    });
    this.isMoveScheduled = true;
  }

  // ...
}

// ...

function isWithinImage(imageBounds, event) {
  let { bottom, left, right, top } = imageBounds;
  let { pageX, pageY } = event;

  return pageX > left && pageX < right && pageY > top && pageY < bottom;
}

// ...

function toDocumentBounds(bounds) {
  let { scrollX, scrollY } = window;
  let { bottom, height, left, right, top, width } = bounds;

  return {
    bottom: bottom + scrollY,
    height,
    left: left + scrollX,
    right: right + scrollX,
    top: top + scrollY,
    width
  };
}
`}</code></pre>
    <p>{`Here's how the `}<inlineCode parentName="p">{`handleMouseMove`}</inlineCode>{` code works:`}</p>
    <p>{`When an event comes in, it calls the browser's
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame"
      }}><inlineCode parentName="a">{`requestAnimationFrame`}</inlineCode></a>{` API to schedule the work of
responding to the event. `}<inlineCode parentName="p">{`requestAnimationFrame`}</inlineCode>{` will invoke the callback passed
to it at the optimal time before the browser paints the next frame.  This way,
we can either move the box or hide it at the best point in time.`}</p>
    <p>{`The method also utilizes an `}<inlineCode parentName="p">{`isMoveScheduled`}</inlineCode>{` instance variable so the work is
not scheduled more than once per frame. When an event comes in and no work is
scheduled, it schedules the work and sets the instance variable to true. If
another event comes in before the scheduled work has run, it will check the
instance variable and not schedule duplicate work. Once `}<inlineCode parentName="p">{`requestAnimationFrame`}</inlineCode>{`
fires and the work is performed, the instance variable is set back to false so
that more work can be scheduled. Without this check, duplicate work could be
scheduled if the user moves the mouse over the image very quickly.`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`requestAnimationFrame`}</inlineCode>{` callback, it checks to see if the mouse event
reports the cursor as within the image or not using the new `}<inlineCode parentName="p">{`isWithinImage`}</inlineCode>{`
utility function. It passes the `}<inlineCode parentName="p">{`imageBounds`}</inlineCode>{`, which we've added a calculation
for in the constructor using the
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect"
      }}><inlineCode parentName="a">{`getBoundingClientRect`}</inlineCode></a>{` API method. This method,
while not the most fun to type, is extremely useful in that it returns to you an
object containing info on the element's position (bottom, left, right, top) and
dimensions (height and width). One caveat of this method is that it gives you
the element's position relative to the browser's viewport, not the beginning of
the document. So if you want to know where the element is relative to the
beginning of the entire document, you need to account for whether and by how
much the page is scrolled. We do this using another utility function,
`}<inlineCode parentName="p">{`toDocumentBounds`}</inlineCode>{`, which uses `}<inlineCode parentName="p">{`window.scrollX`}</inlineCode>{` and `}<inlineCode parentName="p">{`window.scrollY`}</inlineCode>{` to
determine how much the page is scrolled and adds those values to the appropriate
data points for the element's position.`}</p>
    <p><inlineCode parentName="p">{`isWithinImage`}</inlineCode>{` then uses then `}<inlineCode parentName="p">{`pageX`}</inlineCode>{` and `}<inlineCode parentName="p">{`pageY`}</inlineCode>{` properties of the mouse
event, which tell where in the document the cursor was when the event occurred,
to determine if the mouse has left the image or not.  `}<inlineCode parentName="p">{`handleMouseMove`}</inlineCode>{` uses the
result to either call the `}<inlineCode parentName="p">{`updateUI`}</inlineCode>{` method or the `}<inlineCode parentName="p">{`deactivate`}</inlineCode>{` method, which
we'll implement in the next couple of sections.`}</p>
    <h3>{`Changing Zoom Box Position`}</h3>
    <p>{`Okay, after much preliminary work, we're finally ready to change the zoom box's
position. To do this, we'll implement the `}<inlineCode parentName="p">{`updateUI`}</inlineCode>{` method. For now, it'll only
move the zoom box but later we'll also have it move the zoomed image. Here's the
code we need to move the zoom box:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class ImageZoomer {
  constructor(image) {
    // Same code as before...
    this.zoomBoxBounds = toDocumentBounds(this.zoomBox.getBoundingClientRect());
  }

  // ...

  updateUI(mouseX, mouseY) {
    let { imageBounds, zoomBox, zoomBoxBounds } = this;
    let { x: xOffset, y: yOffset } = getZoomBoxOffset(mouseX, mouseY, zoomBoxBounds, imageBounds);
    zoomBox.style.transform = \`translate(\${xOffset}px, \${yOffset}px)\`;
  }
}

// ...
function getZoomBoxOffset(mouseX, mouseY, zoomBoxBounds, imageBounds) {
  let x = mouseX - (zoomBoxBounds.width / 2);
  let y = mouseY - (zoomBoxBounds.height / 2);

  x -= zoomBoxBounds.left;
  y -= zoomBoxBounds.top;

  return {x: Math.round(x), y: Math.round(y)};
}
`}</code></pre>
    <p>{`With those changes in place, the zoom box now appears when the mouse hovers over
the original image, surrounds the cursor, and follows it as it moves over the
image. Here are the important points to note about this code:`}</p>
    <ul>
      <li parentName="ul">{`We capture the position of the zoom box relative to the document as
`}<inlineCode parentName="li">{`this.zoomBoxBounds`}</inlineCode>{` using the same technique we used for the image bounds.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`updateUI`}</inlineCode>{` invokes the new utility function `}<inlineCode parentName="li">{`getZoomBoxOffset`}</inlineCode>{` to figure out
how much we need to move the zoom box relative to its original position at the
top left corner of the zoom container.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`getZoomBoxOffset`}</inlineCode>{` utility calculates the zoom box offset by first
figuring out the position relative to the document that would place the zoom
box around the cursor, subtracting from the X and Y values of the mouse
position half of the width and half of the height of the zoom box (so the
cursor will be in the middle). It then subtracts the original position of the
zoom box relative to the document from those values to yield the amount to
move the zoom box in the X and Y directions.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`updateUI`}</inlineCode>{` then moves the zoom box by applying a `}<a parentName="li" {...{
          "href": "http://learn.shayhowe.com/advanced-html-css/css-transforms/#two-dimensional-transforms"
        }}>{`translate
transform`}</a>{` to it using the offset values from
`}<inlineCode parentName="li">{`getZoomBoxOffset`}</inlineCode>{`. The translate transform supports hardware acceleration via
the GPU the same way as opacity, which we discussed earlier. This means the
browser can move the zoom box without doing any re-painting, which is great
for performance. This is in contrast to properties like `}<inlineCode parentName="li">{`top`}</inlineCode>{` and `}<inlineCode parentName="li">{`left`}</inlineCode>{` that
do cause re-paints.`}</li>
    </ul>
    <h3>{`Hiding the Zoom Box`}</h3>
    <p>{`Next we need to hide the zoom box when the mouse leaves the original image.
Additionally, we must switch our event listening back from mouse move to mouse
enter so we'll know the next time the user hovers over the image. The logic for
this is simple compared to what we've already implemented. We just need to
implement the `}<inlineCode parentName="p">{`deactivate`}</inlineCode>{` method to remove the `}<inlineCode parentName="p">{`active`}</inlineCode>{` class from the zoom box
(we'll also remove it from the zoom window to get that out of the way):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class ImageZoomer {
  // ...

  deactivate() {
    this.zoomBox.classList.remove('active');
    this.zoomWindow.classList.remove('active');
    this.listenForMouseEnter();
  }

  // ...
}
`}</code></pre>
    <h2>{`Containing the Zoom Box in the Image`}</h2>
    <p>{`At this point, we show, move, and hide the zoom box based on mouse movement. The
final concern to address with the zoom box is keeping it from moving outside the
original image when the mouse gets close to the image's edge. In general, the
zoom box should surround the cursor with it in the middle. But once it hits the
edge of the image it should move no further in that direction. Thankfully, this
only requires a small update to the code that calculates the zoom box offset and
the creation of one more utility. Here's the updated `}<inlineCode parentName="p">{`getZoomBoxOffset`}</inlineCode>{` function
and a new `}<inlineCode parentName="p">{`containNum`}</inlineCode>{` utility:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// ...

function containNum(num, lowerBound, upperBound) {
  if (num < lowerBound) {
    return lowerBound;
  }
  if (num > upperBound) {
    return upperBound;
  }
  return num;
}

// ...

function getZoomBoxOffset(mouseX, mouseY, zoomBoxBounds, imageBounds) {
  let x = mouseX - (zoomBoxBounds.width / 2);
  let y = mouseY - (zoomBoxBounds.height / 2);

  x = containNum(x, imageBounds.left, imageBounds.right - zoomBoxBounds.width);
  y = containNum(y, imageBounds.top, imageBounds.bottom - zoomBoxBounds.height);

  x -= zoomBoxBounds.left;
  y -= zoomBoxBounds.top;

  return {x: Math.round(x), y: Math.round(y)};
}

// ...
`}</code></pre>
    <p>{`The new lines in `}<inlineCode parentName="p">{`getZoomBoxOffset`}</inlineCode>{` are the ones that call the new `}<inlineCode parentName="p">{`containNum`}</inlineCode>{`
utility. `}<inlineCode parentName="p">{`containNum`}</inlineCode>{` simply takes a number, a lower bound number, and an upper
bound number, and returns either the number or one of the bounds if the number
is outside the bounds. `}<inlineCode parentName="p">{`getZoomBoxOffset`}</inlineCode>{` uses it to adjust the x and y offsets
for the zoom box so they can't move the zoom box outside the image. For example,
it adjusts the X offset by passing the current X value, the left bound of the
image, and the right bound of the image minus the width of the zoom box (to
account for the horizontal position of the zoom box being set relative to its
left edge, not its center).`}</p>
    <h2>{`Moving the Zoomed Image`}</h2>
    <p>{`The last major feature of the image zoomer for us to implement is moving the
zoomed image to match the zoom box. Given that we implemented event listeners
and a UI update method for the zoom box, adding support for moving the zoomed
image won't be too complex. Here's the code update:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class ImageZoomer {
  // ...

  moveZoomedImage(xPercent, yPercent) {
    let { zoomedImage } = this;
    let xOffset = Math.round(zoomedImage.clientWidth * xPercent) * -1;
    let yOffset = Math.round(zoomedImage.clientHeight * yPercent) * -1;

    zoomedImage.style.transform = \`translate(\${xOffset}px, \${yOffset}px)\`;
  }

  updateUI(mouseX, mouseY) {
    // Same code as before...

    this.moveZoomedImage(xOffset / imageBounds.width, yOffset / imageBounds.height);
  }
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`updateUI`}</inlineCode>{` method now calls a new `}<inlineCode parentName="p">{`moveZoomedImage`}</inlineCode>{` method, passing it the
percentages of how much to move the zoomed image in the horizontal and vertical
directions. It calculates these percentages by dividing the offset applied to
the zoom box by the corresponding dimension of the original image (e.g.
horizontal zoom box offset / width of original image).`}</p>
    <p><inlineCode parentName="p">{`moveZoomedImage`}</inlineCode>{` then uses those percentages to move the zoomed image. For each
direction, it calculates the offset by multiplying the percentage by the
corresponding dimension of the zoomed image (e.g. X percentage * width of zoomed
image). It then multiplies that offset by -1 to account for the zoomed image
needing to move the same relative distance as the zoom box, but in the opposite
direction. Finally, it sets a translate transform to the zoomed image to
performantly change its position.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`There you have it: a working image zoomer component. If you want to play around
with it more, I've posted `}<a parentName="p" {...{
        "href": "https://codepen.io/whastings/pen/zNOYxq"
      }}>{`the code from this walkthrough on
CodePen`}</a>{`. Let's recap what we've covered:`}</p>
    <ul>
      <li parentName="ul">{`Setting up a class to define the state and behavior for our component.`}</li>
      <li parentName="ul">{`Setting up the initial DOM structure for the image zoomer using browser APIs
like `}<inlineCode parentName="li">{`document.createElement`}</inlineCode>{`, `}<inlineCode parentName="li">{`classList`}</inlineCode>{`, and `}<inlineCode parentName="li">{`appendChild`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Writing styles that lay out the original image and zoomed image and position
them appropriately for dynamic movement.`}</li>
      <li parentName="ul">{`Sizing and moving the zoom box.`}</li>
      <li parentName="ul">{`Setting up event listeners for mouse movement.`}</li>
      <li parentName="ul">{`Showing, moving, and hiding the zoom box and zoomed image using the
hardware-accelerated CSS properties `}<inlineCode parentName="li">{`opacity`}</inlineCode>{` and `}<inlineCode parentName="li">{`transform`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`If you liked this post, note that I plan to write in the future on implementing
tooltips and modals with vanilla JS. If you'd like to discuss this further, feel
free to `}<a parentName="p" {...{
        "href": "https://twitter.com/WillHPower"
      }}>{`reach out to me on Twitter at @WillHPower`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      